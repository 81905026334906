const Deleted = () => {
  
    return (
      <>
      <div className='formcard'>
      <h4>
              Tracker deleted. If you had any issues or feedback, feel free to contact me at support@wildpermits.com
          </h4>
      </div>
      </>
    );
  };
  
  export default Deleted;