import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Footer from './components/Footer'
import Dashboard from './pages/Dashboard'
import Weather from './pages/Weather' //FIX??
import Login from './pages/Login'
import Register from './pages/Register'
import ForgotPass from './pages/ForgotPass'
import Reset from './pages/Reset'
import React, { Component } from 'react';
import WelcomePage from './pages/WelcomePage'
import ScrollToTop from './ScrollToTop'
import Contact from './pages/Contact'
import About from './pages/About'
import Yosemite from './pages/Yosemite'
import WhitneyRedirect from './pages/WhitneyRedirect'
import KingRangeRedirect from './pages/KingRangeRedirect'
import EnchantmentsRedirect from './pages/EnchantmentsRedirect'
import SekiRedirect from './pages/SekiRedirect'
import InyoRedirect from './pages/InyoRedirect'
import YosemiteRedirect from './pages/YosemiteRedirect'
import DesolationRedirect from './pages/DesolationRedirect'
import Deleted from './pages/deleted'


function App() { /*add new routes here*/
  return (
    
    <>
      <Router>
      <ScrollToTop />
          <Header />
          <Routes>
            <Route path='/' element={<WelcomePage />} />
            <Route path='/about' element={<About />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/conditions' element={<Weather />} />
            <Route path='/register' element={<Register />} />
            <Route path='/yosemite' element={<Yosemite />} />
            <Route path='/forgot_pass' element={<ForgotPass />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/users/reset-password/:token' element={<Reset />} />
            <Route path='/445860' element={<WhitneyRedirect />} />
            <Route path='/72192' element={<KingRangeRedirect />} />
            <Route path='/233261' element={<DesolationRedirect />} />
            <Route path='/233273' element={<EnchantmentsRedirect />} />
            <Route path='/445857' element={<SekiRedirect />} />
            <Route path='/233262' element={<InyoRedirect />} />
            <Route path='/445859' element={<YosemiteRedirect />} />
            <Route path='/deleted' element={<Deleted />} />
            
          </Routes>
          <Footer />
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
